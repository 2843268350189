import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import Call from '../components/Call';

class BlogTag extends React.Component {
  render() {
    const tag = this.props.pageContext.tag;
    const { posts } = this.props.data.blog;
    return (
      <Layout bodyClass='page-blog'>
        <SEO title={`${tag} | Blog`} />
        <div className='container pb-6 pt-6 pt-md-10 pb-md-10'>
          <div className='row justify-content-start'>
            <div className='col-12 col-md-8'>
              <h1 className='title'>Tag: {tag}</h1>
              {posts.map((post) => (
                <article key={post.id}>
                  <Link to={`/blog${post.fields.slug}`}>
                    <h2>{post.frontmatter.title}</h2>
                  </Link>
                  <small>
                    {post.frontmatter.author}, {post.frontmatter.date}
                  </small>
                  <p>{post.excerpt}</p>
                </article>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query BlogTagQuery($tag: String!) {
    blog: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(posts)/" }
        frontmatter: { tags: { in: [$tag] } }
      }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          author
          tags
        }
        excerpt
        id
        fields {
          slug
        }
      }
    }
  }
`;

export default BlogTag;
